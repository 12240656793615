<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-3">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-manage-layout')"
        >
          New Row
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-for="(layout, index) of cmsLayouts"
      :key="index"
    >
      <b-col>
        <b-card class="mb-1 p-3 shadow">
          <b-card-title>
            <b-row>
              <b-col>
                <h5>
                  Row #{{ layout.position }}
                  <b-badge
                    v-if="layout.isSlider"
                    variant="success"
                  >
                    Slider - {{ layout.slidesPerView }} items:row
                  </b-badge>
                  <b-badge
                    v-if="layout.backgroundType !== 'NO_BG'"
                    :style="`background-color: ${layout.backgroundColor}`"
                  >
                    {{ layout.backgroundType }}: ({{ layout.backgroundColor }})
                  </b-badge>
                </h5>
              </b-col>
              <b-col class="text-right">
                <b-button-group>
                  <b-button
                    type="button"
                    size="sm"
                    variant="warning"
                    @click="onUpdateLayout(layout)"
                  >
                    Update row
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="onRemoveLayout(layout.id)"
                  >
                    Remove row
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-card-title>
          <hr>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Title">
                <b-input
                  v-if="!isImageTitle(layout)"
                  v-model="layout.title"
                  disabled
                />
                <b-img
                  :src="layout.imageSrc"
                  height="50px"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Title type">
                <b-input
                  v-model="layout.titleType"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Position">
                <b-input
                  v-model="layout.position"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-card-text>
            <b-row class="mb-2">
              <b-col class="text-right">
                <b-button-group>
                  <b-button
                    size="sm"
                    variant="success"
                    @click="onOpenCreateRow(layout.id)"
                  >
                    New column
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  :fields="fields"
                  :items="layout.rows"
                  responsive
                >
                  <template #cell(#)="data">
                    <div>
                      {{ data.index + 1 }}
                    </div>
                  </template>
                  <template #cell(youtubeIframe)="data">
                    <div v-if="data.value">
                      <b-button
                        variant="info"
                        size="sm"
                        @click="isShowYoutubeIframe = !isShowYoutubeIframe"
                      >
                        Show youtube iframe
                      </b-button>
                      <div
                        v-if="isShowYoutubeIframe"
                        class="yt-iframe-wrapper"
                        v-html="data.value"
                      />
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>
                  <template #cell(imageSrc)="data">
                    <b-img
                      v-if="data.value"
                      :src="data.value"
                      width="auto"
                      height="150px"
                    />
                    <div v-else>
                      -
                    </div>
                  </template>
                  <template #cell(content)="data">
                    <div
                      v-if="data.value"
                      v-html="data.value"
                    />
                    <div v-else>
                      -
                    </div>
                  </template>
                  <template #cell(breakpoint)="data">
                    <strong>{{ data.item.lg }}/{{ data.item.md }}/{{
                      data.item.sm
                    }}/{{ data.item.cols }}</strong>
                  </template>
                  <template #cell(id)="data">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="onRemoveRow(layout.id, data.value)"
                    >
                      Remove
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <ManageCmsLayout
      :layout-data="updateLayoutData"
      @updated="onClearUpdateLayoutData"
    />
    <CreateCmsRow :layout-id="currentLayoutId" />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    ManageCmsLayout: () => import('./manage-layout.vue'),
    CreateCmsRow: () => import('./create-row.vue'),
  },
  data() {
    return {
      isShowYoutubeIframe: false,
      currentLayoutId: null,
      updateLayoutData: null,
      fields: [
        '#',
        {
          key: 'cardType',
          label: 'Card type',
        },
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'content',
          label: 'Content',
        },

        {
          key: 'youtubeIframe',
          label: 'YT Iframe',
          thStyle: {
            maxWidth: '150px',
          },
        },
        {
          key: 'breakpoint',
          label: 'Breakpoint(lg/md/sm/cols)',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['cmsLayouts', 'siteId']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  methods: {
    ...mapActions(['removeCmsLayout', 'removeCmsRow']),
    onRemoveLayout(layoutId) {
      this.$bvModal.msgBoxConfirm('Are you sure?').then((value) => {
        if (value) {
          this.removeCmsLayout({ layoutId, siteId: this.siteId })
        }
      })
    },
    isImageTitle(layout) {
      if (layout.imageSrc.length > 0) {
        return true
      }
      return false
    },
    onOpenCreateRow(layoutId) {
      this.currentLayoutId = layoutId
      this.$bvModal.show('cms-create-row')
    },
    onRemoveRow(layoutId, rowId) {
      this.removeCmsRow({ layoutId, rowId, siteId: this.siteId })
    },
    onUpdateLayout(layoutData) {
      this.updateLayoutData = layoutData
      this.$bvModal.show('cms-manage-layout')
    },
    onClearUpdateLayoutData() {
      this.updateLayoutData = null
    },
  },
}
</script>
